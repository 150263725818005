@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/react-mask-editor/dist/style.css';

:root{
	color-scheme: light;
	--techwave-site-bg-color: #fff;
	--techwave-header-bg-color: #f5f5f5;
	--techwave-some-r-bg-color: #f5f5f5; 
	/* --techwave-some-a-bg-color: #fff;  */
	--techwave-main-color: #7c5fe3;
	--techwave-main-color1: #8768f8;
	--techwave-main-color2: #b7a4fb;
	--techwave-heading-color: #333;
	--techwave-body-color: #444;
	--techwave-border-color: #ddd;
	--techwave-button-bg-color: #f5f5f5;
	--techwave-hover-color: #000;
	--techwave-heading-font-family: 'Heebo', sans-serif;
	--techwave-body-font-family: 'Work Sans', sans-serif;
	--techwave-left-panel-width: 300px;
	--techwave-header-height: 100px;
}

.loading-indicator:before {
  content: '';
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: ' ';
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size:1.2rem;        
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.fn__generation_item{
	padding: 31px 40px 10px;
	border-bottom: 1px solid var(--techwave-border-color);
	position: relative;
}
.fn__generation_item:hover{
	z-index: 10;
}
.fn__generation_item .item_header{
	padding: 0 0 20px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	justify-content: space-between;
	column-gap: 20px;
	-ms-align-items: center;
	align-items: center;
	width: 100%;
	position: relative;
	z-index: 1;
}
.fn__generation_item .item_header .item_options{
	width: 100%;
    width: auto;
	-webkit-box-pack: end;
    justify-content: flex-end;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	column-gap: 5px;
}
.fn__generation_item .item_header .title_holder{
	width: 100%;
}
.fn__generation_item .item_header .prompt_title{
	margin: 0;
	padding: 0;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	letter-spacing: 0;
	color: var(--techwave-heading-color);
    font-family: var(--techwave-heading-font-family);
	align-self: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    --chakra-line-clamp: 1;
}
.fn__generation_item .item_header .negative_prompt_title{
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	letter-spacing: 0;
	color: var(--techwave-body-color);
    font-family: var(--techwave-heading-font-family);
	align-self: flex-end;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    --chakra-line-clamp: 1;
}

.fn__generation_item{padding-left: 20px;padding-right: 20px;}

.fn__generation_item{
  padding-left: 10px;
  padding-right: 10px;
}

.fn__mobile_pricing .pricing__item_list{
	padding: 10px 0px;
}
.fn__mobile_pricing .pricing__item_list_item{
	padding: 7px 10px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	justify-content: space-between;
	column-gap: 20px;
}
.fn__mobile_pricing .pricing__item_list .title{
	font-size: 14px;
    margin: 0;
    padding: 0;
    letter-spacing: .5px;
    font-weight: 400;
    color: var(--techwave-body-color);
    font-family: var(--techwave-heading-font-family);
	text-align: left;
}
.fn__mobile_pricing .pricing__item_list .desc{
	font-size: 16px;
    margin: 0;
    padding: 0;
    letter-spacing: .5px;
    font-weight: 400;
    color: var(--techwave-heading-color);
    font-family: var(--techwave-heading-font-family);
	text-align: right;
	white-space: nowrap;
}

.fn__generation_list{
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap;
	margin-left: -20px;
}
.fn__generation_list .fn__gl_item{
	margin: 0;
	padding: 0;
	padding-left: 20px;
	width: 25%;
	margin-bottom: 20px;
}
.fn__generation_list .fn__gl__item{
	width: 100%;
	position: relative;
}
.fn__generation_list .fn__gl__item:before{
    content: "";
	height: 0px;
    display: block;
    /* padding-bottom: 100%; */
}
.fn__generation_list .abs_item{
	/* position: absolute; */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.fn__generation_list .abs_item img{
	/* width: 100%;
	height: 100%;
	object-fit: cover; */
	border-radius: 5px;
}
.fn__generation_list .fn__gl__item:hover .all_options{
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}

.fn__generation_list .fn__gl__item:hover .all_options_duration{
	transform: translateY(-85px);
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}
.fn__generation_list .all_options{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	position: absolute;
	bottom: 5px;
	/* left: 10px;
	right: 10px; */
	flex-wrap: wrap;
	transform: translateY(20px);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all .3s ease;
}
.fn__generation_list .all_options_duration{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	visibility: visible;
	position: absolute;
	flex-wrap: wrap;
	transform: translateY(10px);
	opacity: 1;
	pointer-events: none;
	transition: all .3s ease;
}
.fn__generation_list .all_options .fn__icon_options{
	margin: 0 5px 5px 0;
}

.fn__token_info{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	height: var(--techwave-header-height);
	position: relative;
}
.fn__token_info:hover .token__popup{
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.fn__token_info .token__popup{
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	width: 200px;
	position: absolute;
	left: 0;
	top: 100%;
	background-color: var(--techwave-header-bg-color);
	padding: 12px 15px;
	border-radius: 0 0 5px 5px;
	border: 1px solid var(--techwave-border-color);;
	margin-top: -1px;
	font-family: var(--techwave-heading-font-family);
	font-size: 12px;
	letter-spacing: .5px;
	transition: all .2s ease;
}
.fn__token_info .token__popup span{
	color: var(--techwave-heading-color);
}
.fn__token_info .token_summary{
	border-radius: 5px;
	background-color: var(--techwave-some-a-bg-color);
	font-family: var(--techwave-heading-font-family);
	line-height: 16px;
	height: 40px;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center;
	padding: 5px 12px;
	/* margin-right: 20px; */
}
.fn__token_info .count{
	font-weight: 600;
	/* font-size: 18px; */
	color: var(--techwave-heading-color);
	padding-right: 25px;
	position: relative;
}
.fn__token_info .text{
	line-height: 16px;
	/* font-size: 12px; */
}
.fn__token_info .count:after{
	content: '';
	position: absolute;
	right: 12px;
	top: 50%;
	margin-top: -15px;
	height: 30px;
	background-color: #413e45;
	width: 1px;
}

@media(max-width: 768px){
	.fn__mobile_pricing .pricing__item{
		width: 100%;
	}
	.fn__generation_list .fn__gl_item{width: 50%;}
	.fn__model_item{width: 50%;}
	.fn__gallery_items .item__info{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
	.techwave_fn_footer_content{
		flex-wrap: wrap;
	}
	.fn__grid_items li{
		width: 50%;
	}
	.fn__gallery_item{
		width: 50%;
	}
	/* .techwave_fn_header .header__left{
		display: none;
	} */
	.techwave_fn_header{
		justify-content: flex-end;
	}
}
@media(max-width: 480px){
	.techwave_fn_sign,
	.techwave_fn_sign .form__content,
	.techwave_fn_changelog .item__header,
	.techwave_fn_changelog .item__content,
	.techwave_fn_accordion .acc__header,
	.techwave_fn_accordion .acc__content{
		padding-left: 10px;
		padding-right: 10px;
	}
	.fn__generation_list .fn__gl_item{width: 100%;}
	.techwave_fn_image_generation_page .generation_header,
	.fn__generation_item{
		padding-left: 10px;
		padding-right: 10px;
	}
	.fn__model_item{width: 100%;}
	.fn__grid_items ul{
		margin-left: -10px;
	}
	.fn__grid_items li{
		padding-left: 10px;
		margin-bottom: 10px;
	}
	.fn__nav_bar .user_opener{
		width: 50px;
		height: 50px;
	}
	.fn__details_list li{
		width: 100%;
	}
	.fn__gallery_item{
		width: 100%;
	}
	.techwave_fn_header{
		padding-right: 10px;
	}
	.fn__nav_bar .item_opener .dark_mode{
		margin: -9px 0 0 -9px;
	}
	.fn__nav_bar .bar__item{
		margin-right: 5px;
	}
	.fn__nav_bar .bar__item_user{
		margin-left: 5px;
	}
	.fn__nav_bar .item_opener{
		width: 40px;
		height: 40px;
	}
	.fn__nav_bar .item_opener .fn__svg{
		width: 18px;
		height: 18px;
	}
}

.gradient-text {
	@apply bg-clip-text text-transparent bg-gradient-to-tr from-[#37cdbe] via-[#017297] to-[#37cdbe];
  }

.style2{
@apply text-[50px] font-bold
}

.style2 .style1 {
@apply relative
}

strong {
	@apply font-bold
}

.p {
	@apply text-[18px]
  }

.style1{
	@apply text-[43px] font-bold
  }

@grid-size: 10px;

.react-mask-editor-outer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .react-mask-editor-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow: auto;
    flex: 1 1 auto;

    .all-canvases {
      position: relative;
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mask-canvas {
    z-index: 10;
  }

  .cursor-canvas {
    z-index: 20;
    background-color: transparent;
  }
}